import { css } from '@emotion/react'
import styled from '@emotion/styled'
import tw from '@tailwindcssinjs/macro'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import React from 'react'
import Zoom from 'react-medium-image-zoom'

import ChatUserInitial from './ChatUserInitial'

export default function ChatMessageItem(props) {
  return (
    <ChatAnswerItem draggable="false">
      {props.payload.usr ? (
        <ChatUserInitial user={props.payload.usr} draggable="false" />
      ) : (
        <ChatUserImage src="/usr-icon.png" draggable="false" />
      )}
      <ChatMessageWrapper draggable="false">
        <ChatAnswerUser draggable="false">
          {props.payload.usr !== '' && props.payload.usr !== null && props.payload.usr !== undefined
            ? props.payload.usr
            : '<No Username>'}
        </ChatAnswerUser>
        {props.payload.type === 'text' ? (
          <ChatAnswerText draggable="false"> {parse(props.payload.msg)}</ChatAnswerText>
        ) : null}
        {props.payload.type === 'image' ? (
          <Zoom>
            <ChatAnswerImage draggable="false" src={props.payload.msg + '?tr=w-1024'} />
          </Zoom>
        ) : null}
      </ChatMessageWrapper>
    </ChatAnswerItem>
  )
}

ChatMessageItem.propTypes = {
  payload: PropTypes.object,
  usr: PropTypes.string,
  msg: PropTypes.string,
  user: PropTypes.string,
  type: PropTypes.string,
}

const ChatAnswerImage = styled.img(tw`
  w-64
  h-auto
`)
const ChatUserImage = styled.img(tw`
    h-10
    w-10
`)
const ChatAnswerText = styled.p(
  tw`
    w-full
`,
  css`
    hyphens: auto;
  `
)
const ChatAnswerItem = styled.li(tw`
    w-full
    flex
    flex-row
    my-2
    px-4
`)

const ChatMessageWrapper = styled.div(tw`
    flex
    flex-col
    ml-2
`)

const ChatAnswerUser = styled.p(tw`
    mr-2
    font-bold
`)
