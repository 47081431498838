import {
  DISABLE_CHAT,
  ENABLE_CHAT,
  SET_ACCESSTOKEN,
  SET_CHATTAB,
  SET_CURRENTCOLOR,
  SET_CURRENTPAGE,
  SET_HOSTNAME,
  SET_POINTER,
  SET_SECONDARYCHATTAB,
  SET_USERNAME,
  TOGGLE_CHAT,
} from 'actions/types'
import { useMemo } from 'react'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

let store

const initialState = {
  chatOpen: true,
  chatDisabled: true,
  username: null,
  currentColor: '#000',
  currentChatTab: 'global',
  secondaryChatTab: '',
  currentPage: '',
  hostname: null,
  accessToken: '',
  notificationsDisabled: false,
  pointer: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOSTNAME:
      return {
        ...state,
        hostname: action.payload,
      }
    case TOGGLE_CHAT:
      return {
        ...state,
        chatOpen: !state.chatOpen,
      }
    case DISABLE_CHAT:
      return {
        ...state,
        chatDisabled: true,
      }
    case ENABLE_CHAT:
      return {
        ...state,
        chatDisabled: false,
      }
    case SET_USERNAME:
      return {
        ...state,
        username: action.payload,
      }
    case SET_CURRENTCOLOR:
      return {
        ...state,
        currentColor: action.payload,
      }

    case SET_CHATTAB:
      return {
        ...state,
        currentChatTab: action.payload,
      }
    case SET_SECONDARYCHATTAB:
      return {
        ...state,
        secondaryChatTab: action.payload,
      }
    case SET_CURRENTPAGE:
      return {
        ...state,
        currentPage: action.payload,
      }
    case SET_ACCESSTOKEN:
      return {
        ...state,
        accessToken: action.payload,
      }
    case SET_POINTER:
      return {
        ...state,
        pointer: action.payload,
      }
    default:
      return state
  }
}

function initStore(preloadedState = initialState) {
  return createStore(reducer, preloadedState, composeWithDevTools(applyMiddleware()))
  // return createStoreWithMiddleware(reducer, load(), composeWithDevTools(applyMiddleware()))
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState)

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    })

    // Reset the current store
    store = undefined
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState])
  return store
}
