export default {
  fonts: {
    primary: 'Helvetica',
    secondary: 'Georgia',
  },
  colors: {
    primary: '#ffffff',
    primaryText: '#000000',
    secondary: '#ffffff',
    secondaryText: '#1c63b7',
    alternate: '#000000',
    alternateText: '#ffffff',
    error: '#ff4337',
    warning: '#feb546',
    success: '#97d653',
  },
  participant: {
    previewSize: '150px',
  },
}
