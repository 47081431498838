import styled from '@emotion/styled'
import tw from '@tailwindcssinjs/macro'
import PropTypes from 'prop-types'
import React from 'react'
export default function Icon(props) {
  return <IconWrapper onClick={props.onClick}>{props.children}</IconWrapper>
}

Icon.propTypes = {
  activeState: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  src: PropTypes.any,
  children: PropTypes.any,
}

const IconWrapper = styled.div(tw`
w-11
h-10
px-2
text-lg
flex
justify-center
items-center
hover:bg-gray-400
cursor-pointer
`)
