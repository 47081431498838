import { css } from '@emotion/react'
import styled from '@emotion/styled'
import tw from '@tailwindcssinjs/macro'
import CloseSvg from 'img/close.svg'
import PropTypes from 'prop-types'
import React from 'react'

export default function Notifications(props) {
  return (
    <NotificationsWrapper
      css={
        props.bottom
          ? css`
              bottom: 0;
            `
          : ''
      }
    >
      {props.notifications &&
        props.notifications.map((item) => {
          return (
            <NotificationItem key={item.id}>
              {item.type === 'link' && (
                <NotificationsContentLink target="_blank" href={item.link}>
                  {item.msg}
                </NotificationsContentLink>
              )}
              {item.type === 'text' && <NotificationsContent>{item.msg}</NotificationsContent>}
              <StyledCloseSvg onClick={props.onClick} id={item.id} />
            </NotificationItem>
          )
        })}
    </NotificationsWrapper>
  )
}

Notifications.propTypes = {
  joinConnection: PropTypes.object,
  notifications: PropTypes.array,
  onClick: PropTypes.func,
  bottom: PropTypes.bool,
  css: PropTypes.string,
}

const NotificationsWrapper = styled.div(
  tw`
    w-full
    px-2
    text-white
    mt-2
    z-50
`
)

const NotificationsContent = styled.p(
  tw`
    font-bold
    w-full
`,
  css`
    hyphens: auto;
  `
)
const NotificationsContentLink = styled.a(
  tw`
    font-bold
    w-full
    text-white
    underline
    hover:text-gray-600
`,
  css`
    hyphens: auto;
  `
)
const NotificationItem = styled.div(tw`
    bg-blue-600
    w-full
    px-3
    py-2
    flex
    my-4
`)

const StyledCloseSvg = styled(CloseSvg)(tw`
    w-5
    h-5
    ml-4
    self-start
    cursor-pointer
`)
