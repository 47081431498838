import styled from '@emotion/styled'
import tw from '@tailwindcssinjs/macro'
import GlobeIconSvg from 'img/big-globe.svg'
import PartyIconSvg from 'img/konfetti.svg'
import PropTypes from 'prop-types'
import React from 'react'

export default function ChatTabIcon(props) {
  return (
    <ProppedIconWrapper active={props.activeState ? true : false} onClick={props.onClick}>
      {props.icon === 'globe' && <StyledGlobeIconSvg />}
      {props.icon === 'party' && <StyledPartyIconSvg />}
    </ProppedIconWrapper>
  )
}

ChatTabIcon.propTypes = {
  activeState: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
}

let activeTab = (props) => {
  if (props.active) {
    return tw`
          bg-gray-700
          text-white
        `
  } else {
    return
  }
}

const StyledGlobeIconSvg = styled(GlobeIconSvg)`
  display: block;
  width: 80%;
  height: 80%;
`

const StyledPartyIconSvg = styled(PartyIconSvg)`
  display: block;
  width: 80%;
  height: 80%;
`

const IconWrpper = styled.div(tw`
w-full
px-2
h-full
text-lg
flex
justify-center
items-center
hover:bg-gray-400
cursor-pointer
`)

const ProppedIconWrapper = styled(IconWrpper)`
  ${activeTab}
`
