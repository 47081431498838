import { css } from '@emotion/react'
import theme from 'lib/theme'

export default css`
  // fonts
  @font-face {
    font-family: 'Samsung Sharp Sans';
    font-weight: normal;
    src: url('/fonts/d4778ba833943c519b5eb3e971b562d3.eot');
    src: url('/fonts/d4778ba833943c519b5eb3e971b562d3.eot?#iefix') format('embedded-opentype'),
      url('/fonts/d4778ba833943c519b5eb3e971b562d3.woff2') format('woff2'),
      url('/fonts/d4778ba833943c519b5eb3e971b562d3.woff') format('woff'),
      url('/fonts/d4778ba833943c519b5eb3e971b562d3.ttf') format('truetype'),
      url('/fonts/d4778ba833943c519b5eb3e971b562d3.svg#SamsungSharpSansMedium-Regular') format('svg');
  }

  @font-face {
    font-family: 'Samsung Sharp Sans';
    font-weight: bold;
    src: url('/fonts/03fe5644d1605049951f58ca7961c33f.eot');
    src: url('/fonts/03fe5644d1605049951f58ca7961c33f.eot?#iefix') format('embedded-opentype'),
      url('/fonts/03fe5644d1605049951f58ca7961c33f.woff2') format('woff2'),
      url('/fonts/03fe5644d1605049951f58ca7961c33f.woff') format('woff'),
      url('/fonts/03fe5644d1605049951f58ca7961c33f.ttf') format('truetype'),
      url('/fonts/03fe5644d1605049951f58ca7961c33f.svg#SamsungSharpSans-Bold') format('svg');
  }

  // base
  * {
    box-sizing: border-box;
  }

  html {
    padding: 0;
    margin: 0;
  }

  body {
    padding: 0;
    margin: 0;
    color: ${theme.colors.primaryText};
    background: ${theme.colors.primary};
    font-family: ${theme.fonts.primary};
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13px;
    line-height: 1.4em;
    @media only screen and (min-device-width: 375px) {
      font-size: 15px;
      line-height: 1.4em;
    }
  }

  // display images as block elements by default
  img,
  svg {
    display: block;
  }

  // theme-ify links
  a {
    color: ${theme.colors.primaryText};
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      color: ${theme.colors.primaryText};
      text-decoration: underline;
    }
  }

  // remove browser styling from buttons
  button {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    outline: none;
    padding: 0;
    cursor: pointer;
  }

  h1 {
    font-size: 2rem;
    line-height: 1.3em;
  }

  h2 {
    font-size: 1.4rem;
    line-height: 1.4em;
    font-weight: normal;
    font-family: ${theme.fonts.secondary};
  }

  h3 {
    font-size: 1rem;
    line-height: 1.4em;
    font-weight: normal;
  }

  h1,
  h2,
  h3,
  p {
    margin: 0;
  }

  .indent {
    padding-left: 2rem;
  }

  small,
  .small {
    font-size: 0.8rem;
    line-height: 1.3em;
  }

  iframe {
    margin: 0;
    padding: 0;
    border: none;
  }

  .largerEmoji {
    font-size: 21px;
    vertical-align: middle;
  }
`
