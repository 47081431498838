import { css } from '@emotion/react'
import styled from '@emotion/styled'
import tw from '@tailwindcssinjs/macro'
import ChatIconSvg from 'img/chat.svg'
import React from 'react'

export default function ChatIconComponent() {
  return (
    <ChatIconWrapper>
      <StyledChatIcon />
    </ChatIconWrapper>
  )
}
const ChatIconWrapper = styled.div(
  tw`
    mr-8
    cursor-pointer

`,
  css`
    color: currentColor;
    @media (max-width: 414px) {
      margin: 0;
    }
  `
)

const StyledChatIcon = styled(ChatIconSvg)`
  display: block;
`
