import 'emoji-mart/css/emoji-mart.css'
import 'react-medium-image-zoom/dist/styles.css'

import '../styles/base.css'

import { Global } from '@emotion/react'
import { ThemeProvider } from '@emotion/react'
import * as Sentry from '@sentry/node'
import Chat from 'components/Chat'
import theme from 'lib/theme'
import Head from 'next/head'
import PropTypes from 'prop-types'
import React from 'react'
import NoSSR from 'react-no-ssr'
import { Provider } from 'react-redux'
import globalStyles from 'styles/globalStyles'

import { useStore } from '../store'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.NEXT_PUBLIC_COMMIT_SHA || 'development',
  })
}

export default function CustomApp({ Component, pageProps, err }) {
  const store = useStore(pageProps.initialReduxState)

  return (
    <ThemeProvider theme={theme} err={err}>
      <Head>
        <script key="adapter.js" src="/compatibility/adapter.js"></script>
        <script key="kurento-utils.js" src="/compatibility/kurento-utils.js"></script>
        <script key="web-streams-polyfill.min.js" src="/compatibility/web-streams-polyfill.min.js"></script>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        ></meta>
      </Head>
      <Global styles={globalStyles} />
      <Provider store={store}>
        <NoSSR>
          <Component {...pageProps} />
          <Chat />
        </NoSSR>
      </Provider>
    </ThemeProvider>
  )
}
CustomApp.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.object,
  err: PropTypes.any,
}
