import styled from '@emotion/styled'
import tw from '@tailwindcssinjs/macro'
import PropTypes from 'prop-types'
import React from 'react'

export default function ChatUserInitial(props) {
  const initial = props.user.split('')[0]
  return (
    <InitialWrapper>
      <InitialCircle>{initial}</InitialCircle>
    </InitialWrapper>
  )
}

ChatUserInitial.propTypes = {
  user: PropTypes.string,
}

const InitialWrapper = styled.div(tw`
    w-10
    h-10
`)

const InitialCircle = styled.div(tw`
    rounded-full
    flex
    justify-center
    items-center
    bg-zalando-orange
    text-white
    font-bold
    w-10
    h-10
    uppercase
`)
