import styled from '@emotion/styled'
import tw from '@tailwindcssinjs/macro'
import PropTypes from 'prop-types'
import React from 'react'
export default function ToggleButton(props) {
  return (
    <ProppedIconWrapper active={props.activeState ? true : false} onClick={props.onClick}>
      <StyledPartyIconSvg>{props.children}</StyledPartyIconSvg>
    </ProppedIconWrapper>
  )
}

ToggleButton.propTypes = {
  activeState: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  src: PropTypes.any,
  children: PropTypes.any,
}

let activeTab = (props) => {
  if (props.active) {
    return tw`
          bg-gray-700
          text-white
        `
  } else {
    return
  }
}

const StyledPartyIconSvg = styled.div(tw`
  flex
  w-10
  justify-center
`)

const IconWrpper = styled.div(tw`
w-10
h-10
px-2
text-lg
flex
justify-center
items-center
hover:bg-gray-400
cursor-pointer
`)

const ProppedIconWrapper = styled(IconWrpper)`
  ${activeTab}
`
